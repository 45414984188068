import React from "react";
import { Helmet } from "react-helmet";
import {
  faChartMixed,
  faMoneyBill,
  faShield,
  faSignalBars,
  faMessages,
  faShareNodes,
  faCogs,
  faRefresh,
  faVideo
} from "@fortawesome/pro-light-svg-icons";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/bids-councils/Hero";
import Content from "../sections/bids-councils/Content";
import Testimonial from "../sections/landing1/Testimonial";
import ImageRight from "../components/Content/ImageRight";
import ImageLeft from "../components/Content/ImageLeft";
import TextCTA from "../components/Content/TextCTA";
import FeatureRow from "../components/Content/FeatureRow";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Solutions for crime partnerships</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero title="Solutions for crime partnerships"></Hero>
        <Content />
        <ImageRight
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/alert-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="A modern crime intelligence platform built with a focus on user experience, engagement and ease of reporting."
          preTitle="Alert"
          title="Powerful intelligence sharing designed for users"
        />
        <ImageLeft
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/smart-radio-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="A next generation solution providing increased reliability, unlimited connectivity, easy to use reports and a cost accessible to all your members."
          preTitle="SmartRadio"
          title="Next-gen radios for ShopWatch systems"
        />
        <TextCTA
          buttonText="Book a demo"
          text="Book an online demo with one of our product experts to see see our solutions in action."
          title="Like what you see?"
        />
        <ImageRight
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/insight-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Manage your radios assets, member details, agreements and access analytics & reports in an easy to use portal."
          preTitle="Insight"
          title="Member analytics and management"
        />
        <ImageLeft
          buttons={[
            {
              text: "Learn more",
              color: "primary",
              to: "/products/bwv-for-crime-partnerships",
              variant: "solid",
            },
          ]}
          text="Smart cloud enabled body camera solution with integrated radio communication and remote evidence upload."
          preTitle="SmartBWV"
          title="Cloud enable smart BWV solution"
        />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default About;

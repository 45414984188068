import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

// @ts-expect-error false positive image import failure
import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
// @ts-expect-error false positive image import failure
import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

interface ThemeProps {
  theme: DefaultTheme;
}

const ContentImg = styled(Box)<ThemeProps>`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Col lg="6">
            <Title variant="hero">
             A complete crime fighting toolbox.
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5 pt-lg-4">
            <Text>
              Our connected ecosystem of crime fighting solutions are designed with crime 
              reduction partnerships in mind, each a cutting edge product in it's own right 
              but also fully integrated to provide a complete turn key solution. 
            </Text>
            <a href={pdfBrochure} download>
              <Button mt={4} variant="solid" color="light" mr={3}>
                Book a demo
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
